import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import warning from '../images/warning.png';
import '../Products/ProductDetails_style.css';
import restUtils from '../../utils/restUtils';
import { Event } from '../../history';
import renderImages from '../common/RenderImages';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import { MaskPrice } from '../common/MaskPrice';
import { TierTable, TierTableNew, ShowPromoCostExpDetails } from './TierTable';
import { connect } from 'react-redux';
import { CUSTOMER_VIEW, MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { ProductIndicators } from './TierIcon';
import { PRODUCT_DETAILS, VARIABLEPRICING, getImageURl,MONTHLY_SPECIALS_PDP } from '../../urlConstants';
import utilities, {shouldHideSUGG_RETAILForPreference,shouldHideCostForPreference} from '../../utils/utilities';
import BusinessRules from './BusinessRules';

class QuickViewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      assortmentLevels: ['Basic', 'Expanded', 'Dominant', 'Optional'],
      loading: false,
      expand: '',
      serverError: false,
      isNoProducts: false,
    };
  }

  componentDidMount() {
    Event('PRODUCT', 'Quick View', this.props.Item_Number.toString());
    if (this.props.Item_Number) {
      const data = {
        itemId: this.props.Item_Number,
        stockedItem: this.props.productDetails?.stocked,
        isAltPDPapi:this.props.isAltPDPapi
      };
      this.getProductDetails(data);
    }
  }
  expand = () => {
    this.setState({
      expand: 'expan'
    });
  };
  
  getProductDetails = data => {
    let aliasSKURetailerGrpID = this.props.multiStoreDetails ? this.props.multiStoreDetails.aliasSKURetailerGrpID : '';
    const url =
      PRODUCT_DETAILS + data.itemId +
      '&storeId=' + this.props.storeId +
      '&rdc=' + this.props.rdc +
      '&grpCollectionId=' + this.props.groupCollectionId+
      '&aliasSKURetailerGrpID='+aliasSKURetailerGrpID;
    this.setState({ loading: true });
    let product = null;
    let productDetailsPromise =""; 
    const mnthlyspcpayload={
      cartID: "",
      itemNbr: data.itemId,
      isItemDtlFromWH: "false",
      mbrNbr: this.props.storeId,
      promoNbr: this.props.productDetails?.promoNbr,
      rdcNbr: this.props.rdc
    };
    if(data.stockedItem === "Y"){
      productDetailsPromise = restUtils.postData(MONTHLY_SPECIALS_PDP, mnthlyspcpayload)
      .then(response => {
        product = response.data && response.data.MSItemDetailList[0];                      
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false,
          serverError: true
        });
      });

    } else {
    productDetailsPromise = restUtils
      .getData(url)
      .then(response => {
        product = response.data;
        if(!product?.Item_Number && data.isAltPDPapi){
          productDetailsPromise = restUtils.postData(MONTHLY_SPECIALS_PDP, mnthlyspcpayload)
          .then(response => {
            product = response.data && response.data.MSItemDetailList[0];                      
          })
          .catch(error => {
            console.log(error);
            this.setState({
              loading: false,
              serverError: true
            });
          });         
        } else if(!product?.Item_Number){
          this.setState({ isNoProducts: true });
        }
      }).catch(()=>{
        this.setState({
          loading:false,
          serverError:true
        })
      })
    }
    let variableRetail = '';
    let variableRetailPromise = '';
    if(this.props.onVariablePricing){
    variableRetailPromise = restUtils
      .postData(VARIABLEPRICING, [{
        "memberNumber": this.props.storeId,
        "itemNumber": data.itemId
      }])
      .then(response => {
        if (!response.data || response.data.length < 1) return;
        variableRetail = utilities.emptyStringForZero(parseFloat(utilities.zeroForNull(
                            response.data[0].VariablePrice)).toFixed(2));
      }).catch(()=>{
        this.setState({
          loading:false,
          serverError:true
        })
      })
    }
    Promise.all([productDetailsPromise,variableRetailPromise]).then(()=>{
      this.setState({
        product,
        variableRetail,
        loading:false
      })
    }).catch(error=>{
      this.setState({
        loading:false,
        serverError:true
      })
    })
  };

  toggleDiv = divId => {
    this.setState(prevState => {
      return { [divId]: !prevState[divId] };
    });
  };
  
  render() {
    const {  QuickCost,tableType, productDetails, productPlp, viewType, multiStoreDetails } = this.props;
    const { product, variableRetail, isNoProducts } = this.state;
    const future_cost_t2 = productPlp?.future_cost ? productPlp?.future_cost: 0; 
    const future_cost_t1 = productPlp?.T1_zfptcost ? productPlp?.T1_zfptcost: 0;
    const pricingDetails = product.pricing ? product.pricing : {};
    const altImgKey = productDetails && productDetails.altImg ? productDetails.altImg == 1 : false
    const vendorName = altImgKey ? productDetails.vendorName : product.Vendor_Name 
    const img2 =
      'https://images.truevalue.com/getimage.asp?id=' +
      this.props.Item_Number +
      '&ImageType=2&NoImageType=2&ColorImageSize=3&LineArtImageSize=2';
    const altImg =  getImageURl() + `/nsimages/${this.props.Item_Number}` + ".jpg"
      const CentralRDC = product.quantity ? product.quantity[0] : null;
      const getpreferValue= this.props.getprefer && this.props.getprefer.customerView ? this.props.getprefer.customerView : 'c1';
      const displayMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType,getpreferValue);
      const memberCostView =  displayMemberCostAndLbl ?  (pricingDetails.tiered ? (this.props.viewType !== MEMBER_VIEW):  displayMemberCostAndLbl) : displayMemberCostAndLbl
      const showSuggestedRetailPrice  = shouldHideSUGG_RETAILForPreference(this.props.viewType,getpreferValue);
      const suggestedRetailPrices = BusinessRules.suggestedRetailPriceForPdp(product,variableRetail,viewType, multiStoreDetails,getpreferValue);
    return (
      <div id='qvContainer'>
        {this.state.loading && (
          <div>
            <Loader active />
          </div>
        )}
        {
          isNoProducts ? 
          <div className='no_prdcts_display'>
          <h1>No Products to Display!</h1>
          </div>
          :
          (this.state.serverError ? (
            <InternalServerError />
          ) : (
            <>
              <>
                <div className='ui stackable one column grid  '>
                  <div className='sixteen wide column'>
                    <div className='qucikviewHead'>Quick View</div>
                    <b className='productTitle'>{altImgKey ? productDetails.shortDesc : (product.shortdesc || product.Product_Name || product.Product_Title || product.short_desc)} </b>
                    <Link
                      className='QuickVendorName'
                      to={
                        vendorName
                          ? '/vendor/' + utilities.replaceAll(vendorName,'/', '%2F')
                          : '#'
                      }>
                      {vendorName}
                    </Link>
                  </div>
                </div>
                <div className='ui stackable two column grid'>
                  <div className='ten wide column d-center' id='ImageQickview'>
                    {renderImages.getImage({
                      src: altImgKey || (productPlp && productPlp.altImg && productPlp.altImg === 1)  ? altImg : img2,
                      style: {
                        maxWidth: '13rem',
                        maxHeight: '14.28rem',
                        objectFit: 'contain'
                      }
                    })}
                  </div>
                  <div className='six wide column'>
                    <div className='card_wrap'>
                      <div className='card_fields align_twoColumn'>
                        <span className='QuickFirst_level_details_inline'>Item#:</span>
                        <b className='QuickFirst_level_details'>
                          {altImgKey ? productDetails.itemNbr : product && Object.keys(product).length > 0 ? (product?.Item_Number ? product?.Item_Number: productPlp?.Item_Number) : '' }
                        </b>
                        <br />
                        <span className='QuickFirst_level_details_inline'>Model#:</span>
                        <b className='QuickFirst_level_details'>
                          {altImgKey ? productDetails.model : product && Object.keys(product).length > 0 ? (product?.Model ? product?.Model: productPlp?.Model) : ''}
                        </b>
                        <br />
                        <span className='QuickFirst_level_details_inline'>UPC:</span>
                        <b className='QuickFirst_level_details'>
                        {altImgKey ? productDetails?.UpcNum : product && Object.keys(product).length > 0 ? (product?.UpcNum ? product?.UpcNum: productPlp?.UpcNum) : ''}
                        </b>
                        <br />
                        {!utilities.isEmptyOrNullString(product.aliasSku) && (
                          <>
                            <span className='QuickFirst_level_details_inline'>Alternate SKU#:</span>
                            <b className='QuickFirst_level_details'> {parseFloat(product.aliasSku) !== 0 && product.aliasSku} </b>
                          </>
                        )}
                        <br />
                        <span className='QuickFirst_level_details_inline'>Warehouse:</span>
                        <b className='QuickFirst_level_details'>
                          {CentralRDC && CentralRDC.Type === "C" && !utilities.isEmptyOrNullString(CentralRDC.CsRdc) ?
                            `Central Shipped from RDC ${CentralRDC.CsRdc}` :
                            ( altImgKey ? `${this.props.rdc}` : `${this.props.rdc} ${utilities.emptyForNull(product.rdc_name)}, ${utilities.emptyForNull(product.rdc_state)}`)
                          }
                        </b>
                        <br />
                        { memberCostView &&
                          <>
                          <span className='QuickFirst_level_details_inline'>{utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")}:</span>
                          <b className='QuickFirst_level_details'>
                            <MaskPrice
                              getprefer={this.props.getprefer &&
                                this.props.getprefer.customerView
                                ? this.props.getprefer.customerView
                                : 'c1'
                              }
                              viewType={this.props.viewType}
                              fieldVal={( tableType ==='default'|| tableType ==='NewItems' || tableType ==='Discontinued' || tableType ==='CloseoutItems' )
                                          ? pricingDetails.Member_Cost
                                          : tableType === 'HotDeals'
                                          ? pricingDetails.Member_Cost
                                          : utilities.getCostForQty(pricingDetails, '', false)}
                              field={'Member Cost'}
                            />
                          </b>
                        </>
                        }
                        {!altImgKey && <>
                        <span className='QuickFirst_level_details_inline'>
                          Available:
                        </span>
                        <b
                          className={
                            product.Available &&
                            parseInt(product.Available) > 0
                              ? 'QuickFirst_level_details'
                              : 'First_level_details_red'
                          }>
                          {utilities.checkForHubAndSpokeIndictor(product)}
                        </b>
                        <br />
                        <span className='QuickFirst_level_details_inline'>On Ship Later:</span>
                        <b className='QuickFirst_level_details'>0</b>
                        <br />                    
                        <span className='QuickFirst_level_details_inline'>Small Parcel:</span>
                        <b className='QuickFirst_level_details'>{product.FedEx}</b>
                        <br />
                        <span className='QuickFirst_level_details_inline'>Hazmat:</span>
                        <b className='QuickFirst_level_details'>{product.HazMat === 'N' ? 'No' : 'Yes'}</b>
                        <br />
                        {suggestedRetailPrices.map((sr,index)=>{
                            return ( showSuggestedRetailPrice &&
                              <>
                                <span className='QuickFirst_level_details_inline' data-label='Suggested Retail'>
                                  {sr.label}:
                                </span>
                                <b className='QuickFirst_level_details'>
                                <MaskPrice
                                  getprefer={this.props.getprefer &&
                                    this.props.getprefer.customerView
                                    ? this.props.getprefer.customerView
                                    : 'c1'
                                  }
                                  viewType={this.props.viewType}
                                  fieldVal={sr.price?sr.price:0}
                                  field="Suggested Retail"
                                />
                                </b>
                              </>
                            )
                        })}
                        {this.props.viewType !== CUSTOMER_VIEW && (
                          <>
                            {(this.props.multiStoreDetails.access !== 1 || (this.props.multiStoreDetails.access === 1 && this.props.multiStoreDetails.CatMbr)) && this.props.onVariablePricing &&
                              <>
                                <span className='QuickFirst_level_details_inline' data-label='Variable Retail'> Variable Retail: </span>
                                <b className='QuickFirst_level_details' data-label='price'>{ this.state.variableRetail ? `$${utilities.toIntlNumberFormat(this.state.variableRetail)}`: ''}</b>
                              </>
                            }
                          </>
                        )}
                        </>
                      }
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='ui stackable two column grid'
                  style={{ paddingBottom: '0.5rem' }}>
                  <div className='ten wide column'>
                    {this.props.viewType !== CUSTOMER_VIEW &&
                      pricingDetails.tiered ? (
                        <>
                          {(this.props.viewType !== CUSTOMER_VIEW && (this.props.multiStoreDetails.access !== 1 || this.props.multiStoreDetails.CatMbr))
                            ? <TierTableNew pricing={pricingDetails} monthly_special_t2 = {future_cost_t2}  monthly_special_t1 = {future_cost_t1}/>
                            : " "
                          }
                        </>
                      ) : this.props.viewType !== CUSTOMER_VIEW &&
                      !pricingDetails.tiered ? (
                      <ShowPromoCostExpDetails
                        pricing={pricingDetails}
                      />):""
                    }
                      <div className ='indicators-div'>
                          <ProductIndicators product = {product}/>
                      </div>
                    {product.prop65 === 'Y' && (
                      <div className='Quickwarning_container'>
                        {renderImages.getImage({
                          src: warning,
                          className: 'warning_icon'
                        })}
                        <span className='warning prop65_color'>Warning: Prop65 {product.prop65Message && `-${product.prop65Message}`}</span>
                      </div>
                    )}
                  </div>
                  <div className='six wide column'>
                    {/* <p className="quickdescription">{product.Long_Description.length < 250  ? product.Long_Description : (this.state.expand==="expan"? product.Long_Description : product.Long_Description.substr(0, 242) + '...')}<u className="cursor_p" onClick={() => this.expand()}> {this.state.expand==="expan"?"":"more"}</u></p> */}
                    <p className='quickdescription'>
                      {product.Product_Title || product.Long_Description || product.Product_Name}{' '}
                    </p>
                  </div>
                </div>
              </>
            </>
          ))
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return { 
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW, 
    storeId: state.SessionReducer.storeId,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    rdc:state.SessionReducer.rdc,
    address:state.SessionReducer.address,
    getprefer: state.preference.listData,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    groupCollectionId: state.SessionReducer.address ? state.SessionReducer.address.groupCollectionId : '',
    onVariablePricing: state.SessionReducer.multiStoreDetails ? state.SessionReducer.multiStoreDetails.onVariablePricing : false,
  };
};

export default connect(mapStateToProps, null)(QuickViewDetails);
