import React from "react";
import { connect } from "react-redux";
import { Grid, } from "semantic-ui-react";
import utilities from "../../../utils/utilities";
import Dialog from 'react-dialog';
import QuickViewDetails from '../../../views/common/QuickViewDetails';
import {
  getImageElement,
  isQuickViewRequired,
  getProductTitleLabel,
  getQtyField,
  getOverrideRetailField,
} from "./itemListingUtilities";
import MultistoreToCartModal from '../../Cart/MultistoreToCartModal';
import { ProductIndicators } from "./itemListingUtilities";
import newitemindicator from "../../images/newitemindicator.png";

class ItemGridView extends React.Component {
  constructor(props) {
    super(props);
    const { flowType } = props;
    this.state = {
      item: '',
      quickviewdata: '',
      isQuickViewShown: false,
      isQuickViewRequired: isQuickViewRequired(flowType),
    }
  }

  multistoreHandleCarts= (product) => {
    this.setState({
      productSelectedForModal: product,
      isMultistoreModalOpen: true
    });
  };

  availableCheck = (product, index, cartParams,multistore) => { 
    if(product.available === 0){
      utilities.showToast("Product cannot be added");
      return false;
    }else if(this.props.quantities[index][0] > product.available){
      utilities.showToast("Quantity should be less than the available");
      return false;
    }else{
      multistore ? this.multistoreHandleCarts(product) 
        : cartParams.addToCart(product.itemNbr, index, product.sPackType, product.sPackQty, product.maxQtyItemRestriction, product.vendorId,product.isMultiples,product.model,product.maxQtyPerReunion)
    }
  }
 
  closeMultistoreModal=()=>{
    this.props.closeMultistoreModal();
    this.setState({ isMultistoreModalOpen: false });
  }

  delimg = (data) => {
    this.setState({
      quickviewdata: '',
    });
  }

  openModal = (data) => {
    this.setState({
      visible: true,
      item: data,
      expand: ''
    });
    this.props.blurfun && this.props.blurfun(true);
  };

  closeModal = () => {
    this.setState({
      visible: false,
      quickviewdata: ''
    });
    if (this.props.blurfun) {
      this.props.blurfun(false);
    }
  };
  getItemField = (item, index, product,flowType) => {
    const { itemListParams } = this.props;
    const className1 =  (item?.label === "UPC:" || item?.label === "MODEL#:") ? "First_level_details nowrapUpc" :"First_level_details";
    const className = item.classname ? item.classname : className1;
    const field =
      <Grid columns={2}>
        {item.label === "RESTRICTED:" ? (
          <div className={className}>{item.val}</div>
        ) : (
            <>
              <Grid.Column>
                <span className={item.label === 'AVAILABLE:' ? 'availableWH' : item.labelClass ? item.labelClass : "grid-card-name"}>
                {item.label}
                </span>
              </Grid.Column>
              {item.isTextBox ? (
                item.type === "or" ? (
                  flowType !== "4" ?
                  <Grid.Column>
                    <span>
                      {getOverrideRetailField(item, itemListParams.handleQtyChange, item.type)}
                    </span>
                  </Grid.Column>:<Grid.Column />
                ) : (
                    <Grid.Column>
                      <span>
                        {getQtyField(item, itemListParams.handleQtyChange, item.type)}
                      </span>
                    </Grid.Column>
                  )
              ) : (
                <Grid.Column className={item.label === "Max Qty Per Ship Window:" && 'max_red_color'}>
                    <b className={className}>
                      {item.is_number ? (
                        utilities.changePriceToNumber(item.val)
                      ) : (
                            item.val
                          )}
                    </b>
                  </Grid.Column>
                )}
            </>
          )}
      </Grid>
    return field;
  };

  addImghover = (data, num) => {
    this.setState({
      quickviewdata: 'addimg',
      item: num
    });
  }

  render() {
    const { cartParams, itemListParams, itemParams, flowType, relayNbr } = this.props;
    const { product } = itemParams;
    const isReunionListing = "true";
    let msCheck = utilities.msExist(this.props.multiStoreDetails); 
    const isPDPlinkReqd = !((flowType==="4" || flowType==="5") && product.itemNbr=== 0);
    let isAltPDPapi = (flowType && (flowType==="1" || flowType==="2"));
    return (
      <>
        {/* {addToCartModalUtil(cartParams)} */}
        <MultistoreToCartModal
        isMultistoreModalOpen={this.state.isMultistoreModalOpen}
        assortmentId={this.props.assortmentId}
        shoppingAreaName={this.props.shoppingAreaName}
        shoppingAreaId={this.props.shoppingAreaId}
        shoppingAreaMetaId = {this.props.shoppingAreaMetaId}
        closeMultistoreModal={this.closeMultistoreModal}
        flowType={flowType}
        multiStoreCartavailable={this.props.multiStoreCartavailable}
        product={this.state.productSelectedForModal}
        quantitiesMs={this.props.quantitiesMs}
        indexCheck={this.props.indexCheck}
        relayNbr={relayNbr}
        />
        <div className="column gridMdMargin">
          <div className="ui segment plp-grid zero-padding">
            <div className='plp-grid-details' onMouseLeave={() => {
              this.delimg("");
            }}>
              {itemListParams.isSeqRequired && (
                <div>
                  <span className="seq-n-fav">SEQ# :{product.seqNbr}</span>
                </div>
              )}
              {
              product.newItemFlag === 'Y' && flowType === '1' &&
              <img src={newitemindicator} className='new-item-image' style={{'left': '5rem'}} alt=''/>
              }

              <div className="card_img" >
                {getImageElement(
                  this.props.setBackButtonPath,
                  product.itemNbr,
                  itemParams.pdpParams,
                  this.props.imgStyle,
                  this.addImghover,
                  isPDPlinkReqd,
                  product.altImg,
                  isReunionListing,                  
                  this.props.shoppingAreaName,
                  this.props.shoppingAreaMetaId
                )}
              </div>
              {this.state.isQuickViewRequired && isPDPlinkReqd && window.innerWidth > 767 && (
                <div className="gridButton">
                  <span
                    className='view_button-compact-visible'
                    onClick={() => {
                  this.openModal(product.itemNbr);
                    }}
                  >
                    Quick View
                  </span>
                </div>
              )}
                {this.state.visible && this.state.item === product.itemNbr && (
                  <div className='QuickviewDialoggrid' onMouseLeave={this.closeModal} >
                    <Dialog width='1' height='1' position='absolute'  onMouseEnter={() => {
                      this.openModal(product.itemNbr);
                    }}>
                      <QuickViewDetails
                        getprefer={
                          this.props.getprefer &&
                          this.props.getprefer.customerView
                          ? this.props.getprefer.customerView
                          : 'c1'
                      }
                      Item_Number={product.itemNbr}
                      viewType={this.props.viewType}
                      QuickCost={product.Member_Cost}
                      productDetails={product}
                      isAltPDPapi = {isAltPDPapi}
                    />
                  </Dialog>
                </div>
              )}
              <p 
                style={{ marginBottom: '0' }}
                className="t-center vendor-name">
                {product.vendorName}
              </p>
              <p className="t-center product-name-span">
                {getProductTitleLabel(
                  this.props.setBackButtonPath,
                  product.itemNbr,
                  itemParams.pdpParams,
                  product.shortDesc,
                  isPDPlinkReqd,
                  this.props.shoppingAreaMetaId,
                  product.available,
                  isReunionListing,
                  this.props.shoppingAreaName
                )}
              </p>
              <div className="card_wrap">
                <div className="card_fields gridbold">
                  {itemParams.fieldSet.map((item, index) => {
                    return this.getItemField(item, index, product, flowType);
                  })}
                </div> 
                <div className="indicators-div" style ={{'marginTop': '2rem'}}>
                  <ProductIndicators product={product} isPLP="PLP" flowType = {flowType}/>
                </div>
              </div>
            </div>
          </div>
          <div className='gridItemBottom'>
          {itemListParams.isAddToCartRequired && (
          msCheck && msCheck > 1 ?
          <button
          className="red ui fluid button grid_add_to_cart"
         onClick={() => this.multistoreHandleCarts(product)
          }
        >
          Add to Store
        </button>
          :
              <button
                className="red ui fluid button grid_add_to_cart"
                onClick={() =>  this.props.shoppingAreaMetaId === "7" ? this.availableCheck(product,itemParams.itemIndex, cartParams) :
                           cartParams.addToCart(product.itemNbr, itemParams.itemIndex, product.sPackType, product.sPackQty, product.maxQtyItemRestriction, product.vendorId,product.isMultiples,product.model,product.maxQtyPerReunion)}
              >
                Add to Cart
              </button>
            )}
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const carts = state.cart.orders.allCarts;
  return {
    getprefer: state.preference.listData,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
  };
};
export default connect(mapStateToProps)(ItemGridView);
